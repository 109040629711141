export const API_URL =
  process.env.REACT_APP_API_URL ||
  'https://tungwah-staff-training-dev.appicidea.com';
export const IMAGE_URL =
  process.env.REACT_APP_IMAGE_URL || 'https://static.appicidea.com';
export const AUDIO_URL = 'https://static.appicidea.com';
export const USER_CSV_TEMPLATE_URL =
  'https://docs.google.com/spreadsheets/d/1b1AXdgse_xQFM8MG-puvx_oGCO47a-rw/edit?usp=sharing&ouid=103356879006072304732&rtpof=true&sd=true';
export const USER_CSV_TEMPLATE_WITHOUT_DEPT_URL =
  'https://docs.google.com/spreadsheets/d/1LxL_T4NhngbkNPomsj17A4SCtydd4cen/edit#gid=1785393578';
export const PAGE_SIZE = 20;
export const TOAST_TIME = 5000;
export const APP_NAME = 'Tung Wah Staff Traing Admin Portal';
export const IMAGE_SIZE = 1024 * 10000;
export const AUDIO_SIZE = 1024 * 10000;
export const CSV_SIZE = 1024 * 10000;
