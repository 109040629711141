import { TimeStamp } from 'app/models';
import Axios from '.';

/**
 * Get token to retrieve app download link (https://tungwah-staff-training-dev.appicidea.com/doc/#/Download%20App/post_v1_download_login)
 */

export interface DownloadLoginResponse {
  token: string;
}

export interface DownloadLoginParams {
  loginName: string;
  password: string;
}

export const downloadLogin = async (params: DownloadLoginParams) => {
  const { data } = await Axios.post<DownloadLoginResponse>(
    '/download/login',
    params,
  );
  return data;
};

/**
 * get download link by token (https://tungwah-staff-training-dev.appicidea.com/doc/#/Download%20App/get_v1_download_link)
 */

export interface DownloadLinkItem extends TimeStamp {
  downloadLinkid: number;
  url: string;
  type: 'Android' | 'iOS';
}

export const getDownloadLink = async (token: string) => {
  const { data } = await Axios.get<DownloadLinkItem[]>('/download/link', {
    params: { token },
  });
  return data;
};
