import { Department } from '.';
import { TimeStamp } from './Common';

export interface AdminLoginPayload {
  loginName: string;
  password: string;
}
export interface AdminDepartment {
  Department: Department;
  departmentid: number;
}

export interface Admin extends TimeStamp {
  adminid: number;
  hash: string | null;
  loginName: string;
  displayName: string;
  role: AdminType;
  AdminDepartments: AdminDepartment[];
}
export type AdminType = 'Super Admin' | 'Content Operator' | 'Account Operator';

export interface AdminRole {
  role: AdminType;
  label: string;
  color:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';
}
export interface AdminRoles {
  'Super Admin': AdminRole;
  'Content Operator': AdminRole;
  'Account Operator': AdminRole;
}

export const ADMIN_ROLES: AdminRoles = {
  'Super Admin': { role: 'Super Admin', label: '超級管理員', color: 'danger' },
  'Content Operator': {
    role: 'Content Operator',
    label: '內容管理員',
    color: 'warning',
  },
  'Account Operator': {
    role: 'Account Operator',
    label: '帳戶管理員',
    color: 'success',
  },
};
