/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useCallback, useState } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

//i18n
import { AdminType } from 'app/models';

const SidebarContent = props => {
  const ref = useRef<any>();
  const location = useLocation();
  const [currentAdminRole, setCurrentAdminRole] = useState<AdminType>();

  const activateParentDropdown = useCallback(item => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;
    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem: any = null;
      const ul = document.getElementById('side-menu');
      const items = ul?.getElementsByTagName('a');
      if (items) {
        for (let i = 0; i < items.length; ++i) {
          if (pathName === items[i].pathname) {
            matchingMenuItem = items[i];
            break;
          }
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [location.pathname, activateParentDropdown]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  });
  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser') as string);
      setCurrentAdminRole(obj.role);
    }
  }, []);

  const scrollElement = item => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        if (ref && ref.current) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
        }
      }
    }
  };

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%', minHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">功能列表</li>
            {currentAdminRole === 'Super Admin' ||
            currentAdminRole === 'Content Operator' ? (
              <li>
                <NavLink
                  to="/courses"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-book"></i>
                  <span>課程</span>
                </NavLink>
              </li>
            ) : null}
            <li>
              <NavLink
                to="/quizRecords"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-book-content"></i>
                <span>測驗記錄</span>
              </NavLink>
            </li>
            {currentAdminRole === 'Super Admin' ||
            currentAdminRole === 'Content Operator' ? (
              <li>
                <NavLink
                  to="/questions"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-question-mark"></i>
                  <span>問題</span>
                </NavLink>
              </li>
            ) : null}
            {currentAdminRole === 'Super Admin' ? (
              <li>
                <NavLink
                  to="/news"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-news"></i>
                  <span>最新消息</span>
                </NavLink>
              </li>
            ) : null}
            {currentAdminRole === 'Super Admin' ||
            currentAdminRole === 'Account Operator' ? (
              <li>
                <NavLink
                  to="/users"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-user"></i>
                  <span>用戶</span>
                </NavLink>
              </li>
            ) : null}
            <li>
              <NavLink
                to="/roles"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-group"></i>
                <span>用戶群組</span>
              </NavLink>
            </li>

            {currentAdminRole === 'Super Admin' ? (
              <li>
                <NavLink
                  to="/departments"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bxs-user-badge"></i>
                  <span>單位</span>
                </NavLink>
              </li>
            ) : null}
            {currentAdminRole === 'Super Admin' ? (
              <li>
                <NavLink
                  to="/admins"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-user-circle"></i>
                  <span>管理員</span>
                </NavLink>
              </li>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
