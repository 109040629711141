import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { toast } from 'react-toastify';
import { getDownloadLink, downloadLogin } from 'app/services/DownloadService';

interface SuccessItem {
  token: string | null;
  ios: string;
  android: string;
}

interface DownloadState {
  error: string | null;
  success: string | null;
  loginLoading: boolean;
  loginError: string;
  token: string | null;
  iosLink: string | null;
  androidLink: string | null;
}

const initialState = {
  error: '',
  success: 'ok',
  loginLoading: false,
  loginError: '',
  token: null,
  iosLink: null,
  androidLink: null,
} as DownloadState;

const downloadSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    loginUserStart(state) {
      state.loginLoading = true;
      state.loginError = '';
    },
    loginUserSuccess(state, action: PayloadAction<SuccessItem>) {
      state.loginLoading = false;
      state.loginError = '';
      state.token = action.payload.token;
      state.iosLink = action.payload.ios;
      state.androidLink = action.payload.android;
    },
    loginUserFail(state) {
      state.loginLoading = false;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  loginUserStart,
  loginUserSuccess,
  loginUserFail,
} = downloadSlice.actions;
export default downloadSlice.reducer;

// dispatch actions
export const loginUser = (params): AppThunk => async dispatch => {
  dispatch(loginUserStart());
  try {
    const tokenRes = await downloadLogin(params);
    let linkRes: any[] = [];
    if (tokenRes) {
      let res = await getDownloadLink(tokenRes.token);
      linkRes = res;
    }
    let ios = '';
    let android = '';
    linkRes.forEach(res => {
      if (res.type === 'Android') {
        android = res.url;
      }
      if (res.type === 'iOS') {
        ios = res.url;
      }
    });
    dispatch(
      loginUserSuccess({
        token: tokenRes.token || null,
        ios: ios,
        android: android,
      }),
    );
  } catch (err) {
    dispatch(loginUserFail());
    toast.warning('登入失敗，請重試。');
  }
};
