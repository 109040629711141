import { Redirect } from 'react-router-dom';

import { LogoutPage, LoginPage } from 'app/pages/Authentication';
import { AdminListPage, AdminFormPage } from 'app/pages/AdminPage';
import { UserListPage, UserFormPage, UserDetailPage } from 'app/pages/UserPage';
import { HomePage } from 'app/pages/HomePage/Loadable';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { NewsFormPage, NewsListPage } from 'app/pages/NewsPage';
import { CourseFormPage, CourseListPage } from 'app/pages/CoursePage';
import { QuestionFormPage, QuestionListPage } from 'app/pages/QuestionPage';
import { MaterialFormPage, MaterialListPage } from 'app/pages/MaterialPage';
import { QuizFormPage } from 'app/pages/QuizPage';
import { RoleFormPage, RoleListPage } from 'app/pages/RolePage';
import {
  DepartmentFormPage,
  DepartmentListPage,
} from 'app/pages/DepartmentPage';
import {
  QuizRecordFormPage,
  QuizRecordDetailPage,
  QuizRecordListPage,
} from 'app/pages/QuizRecordPage';
import { DownloadPage } from 'app/pages/DownloadPage/Loadable';

interface Route {
  path: string;
  component: any;
  exact?: boolean;
}
const userRoutes: Route[] = [
  // admin
  { path: '/admins', component: AdminListPage },
  { path: '/admins/new', component: AdminFormPage, exact: true },
  { path: '/admins/edit/:adminid', component: AdminFormPage, exact: true },
  {
    path: '/admins/edit/:adminid/:password',
    component: AdminFormPage,
    exact: true,
  },

  { path: '/news', component: NewsListPage },
  { path: '/news/new', component: NewsFormPage },
  { path: '/news/edit/:newsid', component: NewsFormPage },
  { path: '/courses', component: CourseListPage },
  { path: '/courses/new', component: CourseFormPage },
  { path: '/courses/edit/:courseid/:section', component: CourseFormPage },
  { path: '/courses/edit/:courseid/', component: CourseFormPage },
  { path: '/materials', component: MaterialListPage },

  { path: '/courses/materials', component: MaterialListPage },
  { path: '/courses/:courseid/materials', component: MaterialListPage },
  { path: '/courses/materials/new', component: MaterialFormPage },
  { path: '/courses/:courseid/materials/new', component: MaterialFormPage },
  { path: '/courses/materials/edit/:materialid', component: MaterialFormPage },
  { path: '/courses/:courseid/quizes/new', component: QuizFormPage },
  { path: '/courses/:courseid/quizes/edit/:quizid', component: QuizFormPage },
  { path: '/courses/quizes/edit/:quizid', component: QuizFormPage },
  { path: '/questions', component: QuestionListPage },
  { path: '/questions/new', component: QuestionFormPage },
  { path: '/questions/edit/:questionid', component: QuestionFormPage },
  { path: '/quizRecords', component: QuizRecordListPage },
  { path: '/quizRecords/new', component: QuizRecordFormPage },
  {
    path: '/quizRecords/detail/:quizRecordid',
    component: QuizRecordDetailPage,
  },

  { path: '/users', component: UserListPage },
  { path: '/users/new', component: UserFormPage },
  { path: '/users/edit/:userid', component: UserFormPage },
  { path: '/users/detail/:userid', component: UserDetailPage },
  { path: '/roles', component: RoleListPage },
  { path: '/roles/new', component: RoleFormPage },
  { path: '/roles/edit/:roleid', component: RoleFormPage },
  { path: '/departments', component: DepartmentListPage },
  { path: '/departments/new', component: DepartmentFormPage },
  { path: '/departments/edit/:departmentid', component: DepartmentFormPage },
  { path: '/', component: HomePage },
  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

const authRoutes: Route[] = [
  { path: '/user/download', component: DownloadPage },
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage },
  { path: '/notFound', component: NotFoundPage },
];

export { userRoutes, authRoutes };
