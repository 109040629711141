import Axios from '.';
import {
  AdminLoginPayload,
  ListResult,
  Admin,
  Filter,
  AdminType,
} from '../models';

export const adminLogin = async (para: AdminLoginPayload) => {
  const { data } = await Axios.post<string>('/admin/login', para);
  return data;
};

/**
 * Get Admin list (https://tungwah-staff-training-dev.appicidea.com/doc/#/Admin/get_v1_admin_)
 */

export interface GetAdminListParams extends Filter {}

export const getAdminList = async (params: GetAdminListParams) => {
  const { data } = await Axios.get<ListResult<Admin>>('/admin', {
    params,
  });
  return data;
};

/**
 * Create Admin (https://tungwah-staff-training-dev.appicidea.com/doc/#/Admin/post_v1_admin_)
 */

export interface CreateAdminParams {
  displayName: string;
  loginName: string;
  password: string;
  role: AdminType;
  AdminDepartment?: string;
}

export const createOneAdmin = async (para: CreateAdminParams) => {
  const { data } = await Axios.post<Admin>('/admin', para);
  return data;
};

/**
 * Get current Admin profile (https://tungwah-staff-training-dev.appicidea.com/doc/#/Admin/get_v1_admin_profile)
 */

export const getProfile = async () => {
  const { data } = await Axios.get<Admin>('/admin/profile');
  return data;
};

/**
 * Update Admin profile (https://tungwah-staff-training-dev.appicidea.com/doc/#/Admin/patch_v1_admin_profile)
 */

export interface UpdateAdminProfileParams {
  displayName: string;
  role: AdminType;
}

export const updateProfile = async (params: UpdateAdminProfileParams) => {
  const { data } = await Axios.patch<string>('/admin/profile', params);
  return data;
};

/**
 * Reset current Admin pw (https://tungwah-staff-training-dev.appicidea.com/doc/#/Admin/post_v1_admin_resetPassword)
 */

export const resetCurrAdminPw = async (params: { password: string }) => {
  const { data } = await Axios.post<string>('/admin/resetPassword', params);
  return data;
};

/**
 * Get one Admin (https://tungwah-staff-training-dev.appicidea.com/doc/#/Admin/get_v1_admin__adminid_)
 */

export const getOneAdmin = async (adminid: string) => {
  const { data } = await Axios.get<Admin>(`/admin/${adminid}`);
  return data;
};

/**
 * Patch one Admin (https://tungwah-staff-training-dev.appicidea.com/doc/#/Admin/patch_v1_admin__adminid_)
 */

export const updateAdmin = async (
  adminid: number,
  para: { displayName?: string; role?: AdminType; AdminDepartment?: string },
) => {
  const { data } = await Axios.patch<string>(`/admin/${adminid}`, para);
  return data;
};

/**
 * Delete Admin (https://tungwah-staff-training-dev.appicidea.com/doc/#/Admin/delete_v1_admin__adminid_)
 */

export const deleteAdmin = async (adminid: number) => {
  const { data } = await Axios.delete<string>(`/admin/${adminid}`);
  return data;
};

/**
 * Reset one Admin pw (https://tungwah-staff-training-dev.appicidea.com/doc/#/Admin/post_v1_admin__adminid__resetPassword)
 */

export const resetAdminPassword = async (
  adminid: number,
  para: { password?: string },
) => {
  const { data } = await Axios.post<string>(
    `/admin/${adminid}/resetPassword`,
    para,
  );
  return data;
};

export const logout = async () => {
  const { data } = await Axios.post('/logout');
  return data;
};
