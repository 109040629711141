import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Rightbar from 'app/components/CommonForBoth/Rightbar';

import {
  changeLeftSidebarTheme,
  changeLayoutWidthAction,
  changeSidebarType,
  changeTopbarThemeAction,
} from 'store/Layout';

interface Props {
  children: React.ReactChild;
}

function Layout({ children }: Props) {
  const dispatch = useDispatch();
  const {
    showRightSidebar,
    leftSideBarType,
    isPreloader,
    leftSideBarTheme,
    layoutWidth,
    topbarTheme,
  } = useSelector((rootState: RootState) => rootState.layout);

  useEffect(() => {
    if (isPreloader === true) {
      if (document.getElementById('preloader')) {
        document.getElementById('preloader')!.style.display = 'block';
      }
      if (document.getElementById('status')) {
        document.getElementById('status')!.style.display = 'block';
      }

      setTimeout(function () {
        if (document.getElementById('preloader')) {
          document.getElementById('preloader')!.style.display = 'none';
        }
        if (document.getElementById('status')) {
          document.getElementById('status')!.style.display = 'none';
        }
      }, 2500);
    } else {
      if (document.getElementById('preloader')) {
        document.getElementById('preloader')!.style.display = 'none';
      }
      if (document.getElementById('status')) {
        document.getElementById('status')!.style.display = 'none';
      }
    }

    // Scroll Top to 0
    window.scrollTo(0, 0);

    if (leftSideBarTheme) {
      dispatch(changeLeftSidebarTheme(leftSideBarTheme));
    }

    if (layoutWidth) {
      dispatch(changeLayoutWidthAction(layoutWidth));
    }

    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
    if (topbarTheme) {
      dispatch(changeTopbarThemeAction(topbarTheme));
    }
  }, [
    showRightSidebar,
    leftSideBarType,
    isPreloader,
    leftSideBarTheme,
    layoutWidth,
    topbarTheme,
    dispatch,
  ]);
  return (
    <>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header />
        <Sidebar type={leftSideBarType} />
        <div className="main-content">{children}</div>
        <Footer />
      </div>
      {showRightSidebar ? <Rightbar /> : null}
    </>
  );
}

export default Layout;
