import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { RootState } from 'store';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  // DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';

//i18n
// import { useTranslation } from 'react-i18next';

// users
import user1 from 'app/assets/images/users/avatar.png';
import { AdminType, ADMIN_ROLES } from 'app/models';

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState('Admin');
  const [currentDept, setCurrentDept] = useState('');
  // const { t } = useTranslation();
  const [currentAdminRole, setCurrentAdminRole] = useState<AdminType>();
  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser') as string);
      setusername(obj.displayName);
      setCurrentAdminRole(obj.role);
      setCurrentDept(
        obj.AdminDepartments && obj.AdminDepartments[0]
          ? obj.AdminDepartments[0].Department.name
          : '',
      );
    }
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-lg-inline-block ms-2 me-1">{username}</span>
          <Badge
            pill
            className={`badge-soft-${
              ADMIN_ROLES[currentAdminRole as AdminType]?.color
            } ms-1 p-2 font-size-12 rounded badge-pill`}
          >
            {ADMIN_ROLES[currentAdminRole as AdminType]?.label}
          </Badge>
          {currentDept !== '' ? (
            <Badge
              pill
              className={`badge-soft-info ms-2 p-2 font-size-12 rounded badge-pill`}
            >
              {currentDept}
            </Badge>
          ) : null}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block ms-1" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>登出</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
